module.exports = {
  analytics: {
    enable: true,
    debug: process.env.NODE_ENV !== "production",
    defaultSettings: {},
    plugins: [
      {
        name: "google-analytics",
        needConsent: true,
        settings: (authorization, otherAuthorizations) => {
          return {
            measurementIds: ["G-45M5K9GPR9"],
            gtagConfig: {
              anonymize_ip: !authorization,
            },
          };
        },
        script: () => import("@analytics/google-analytics"),
      },
    ],
  },
};
